body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#root {
    height: 100%;
}

.App .ant-layout {
    height: 100%;
}

.main-content {
    height: 100%;
}

.ant-layout-header {
    background: white !important;
    padding: 10px 0 !important;
/*    
  background: #fff6c5 !important;
  font-family: 'Shadows Into Light', cursive;
  font-size: 36px;
  font-weight: bold;
  text-align: left;  
*/  
}

/*
.ant-layout-sider {
    background: #a3ccf3 !important;
}
*/

.ant-layout-content {
    padding-bottom: 100px;
}

.ant-steps-item-tail {
    margin-left: 25px !important;
    padding: 3.5px 20px !important;
}

.ant-layout-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #ffffff !important;
    padding: 12px 50px !important;    
}

.ant-steps-item-title {
    font-size: 10px !important;
}

.ant-steps-item-content {
    width: 50px !important;
}

.ant-steps-item-icon {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.ant-steps-item {
    -webkit-flex: 0 0 !important;
            flex: 0 0 !important;
    text-align: center !important;
}

.ant-steps {
    display: block !important;
}

.col-header {
    text-align: left;
    font-weight: bold;   
    font-size: 13px;
}

.col-header-2 {
    text-align: right;
    font-weight: bold;    
    padding-right: 20px !important;
}

.col-sub-header {
    text-align: left;
    font-weight: bold;    
    padding-left: 20px !important;
}

.col-data {
    text-align: left;
}

.row-header {
    background-color: #90e790;
    text-align: left;
    height: 30px;
    font-size: 18px;
    font-weight: bold;
    padding-left: 20px;
    margin-bottom: 10px;    
}

.row-header-mobile {
    background-color: #90e790;
    text-align: left;
    height: 40px !important;
    font-size: 18px;
    font-weight: bold;
    padding-left: 20px;
    margin-bottom: 10px; 
    padding-top: 5px;  
}

.row-sub-header {
    background-color: #feffa1;
    text-align: left;
    height: 30px;
    font-size: 18px;
    font-weight: bold;
    padding-left: 20px;
    margin-bottom: 10px;    
}

.row-data {
    padding-left: 20px;
    margin-bottom: 10px;
}

.row-data:hover {
    background-color: #cff2e9 !important;
}

.row-id {
    padding: 3px 8px;
    border: 1px solid lightskyblue;
    border-radius: 20px;
    float: right;
    background: white;    
}
.ant-modal-body {
    overflow-x: auto !important;
}

.div-subitem {
    display: inline-block;
    /* float: left; */
    margin-right: 15px;
    margin-bottom: 3px;
    white-space: nowrap;    
}

.div-subitem-title {
    display: inline-block;
    margin-left: 0px;
}    
.div-subitem-content {
    display: inline-block;
    margin-left: 10px !important;
    width: 130px;
}    

.div-subitem-switch {
    margin-left: 10px !important;
} 

.btn-login-submit {
    line-height: 1.499;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    height: 32px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    position: relative;
    border: 1px solid #d9d9d9;

    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 0 rgba(0,0,0,.045);    
    box-sizing: border-box;
    -webkit-appearance: button;
    width: 200px;
}

.div-calculation-group {
    margin-bottom: 15px;
}

.div-calculation-group-total {
    margin-bottom: 20px;
    font-size: 18px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@media (max-width:1377px)  { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */ 
    .div-subitem {
        white-space: nowrap;  
        width: 220px;        
    }    
    .div-subitem-title {
        float: left;
    }    
    .div-subitem-content {
        float: right;
        margin-left: 0px;
        width: 130px !important;
    }    
    .div-subitem-switch {
        margin-left: 0px !important;
        width: 80px !important;
    } 
}

